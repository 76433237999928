.ihCalcIcon {
    margin-top: 50px
}

.ihCalcOverlay,
#ihOverlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(8, 135, 212, 0.8);
    display: none;
    z-index: 99999
}

.ihOverlayCloseBtn {
    top: -17px;
    right: -21px;
    z-index: 999;
    position: absolute;
    cursor: pointer
}

.ihCalcCategoryHeader {
    text-align: left
}

.ihCalcHolder {
    width: 75%;
    max-width: 1200px;
    margin: 20px auto;
    background-color: white;
    height: 95%;
    position: relative
}

@media (max-width: 768px) {
    .ihCalcHolder {
        width: 96%;
        margin: 12px auto;
        background-color: white;
        max-height: 96%;
        -webkit-overflow-scrolling: touch;
        overflow-y: scroll
    }

    .ihCalcHolder .ihOverlayCloseBtn {
        display: block;
        right: 3px;
        top: 7px;
        position: fixed
    }
}

@media (min-width: 769px) and (max-width: 1024px) and (orientation: landscape) {
    .ihCalcHolder {
        width: 94%
    }
}

@media (min-width: 769px) {
    .ihCalcHolder .ihOverlayCloseBtn {
        top: -10px;
        right: -10px
    }
}

.ihCalcCategory {
    height: 258px;
    float: left;
    clear: both;
    width: 100%
}

.ihCalcCategory.ihCustomCalcCategory {
    height: auto
}

.ihCalcCategory ul {
    list-style: none;
    padding-left: 2%
}

.ihCalcCategory ul li {
    list-style: none;
    padding-left: 2%;
    line-height: 25px;
    font-size: 18px;
    font-family: "NexaLight", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    color: #0070cd;
    cursor: pointer
}

.ihCalcCategory ul li::before {
    content: '\f105\f105';
    font-family: 'FontAwesome';
    display: inline-block;
    margin: 0 10px 0 -20px;
    width: 20px;
    letter-spacing: 0px;
    color: #6C7581
}

.ihCalcOverlayClose {
    position: fixed;
    right: 0;
    top: 0;
    cursor: pointer;
    width: 40px;
    height: auto
}

#ihCalcIframe {
    width: 100%;
    height: 100%
}

.ihCalcCategory.ihCustomCalcCategory+div {
    clear: both
}

.ihProductList .ihProductArrowLeft {
    left: 0;
    top: 50%
}

.ihProductList .ihProductArrowRight {
    right: 0;
    top: 50%
}

.ihProductList .ihProductItem ul li {
    font-size: 16px
}

.ihFullColumn .ihProductList ul {
    width: 95%;
    margin: 0 auto
}

.ihOverlayContent {
    width: 85%;
    background-color: white;
    margin: 40px auto;
    padding: 2%;
    max-width: 968px;
    -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    position: relative
}

.ihEmailSignUp {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    color: #6C7581;
    -webkit-transition: all .6s ease-in-out 0s;
    transition: all .6s ease-in-out 0s;
    overflow-y: scroll;
    max-height: 650px;
    min-height: 400px
}

.ihBlueBtn,
#mc_embed_signup .ihBlueBtn {
    position: relative;
    display: inline-block;
    padding: 9px 21px;
    border: 2px solid #0070cd !important;
    font-family: "Nexa", Calibri, Candara, Segoe, "Segoe UI", Optima, Arial, sans-serif;
    font-weight: normal;
    text-align: center;
    line-height: 1em;
    color: #fff !important;
    background: #0070cd;
    text-decoration: none;
    border-radius: 27px;
    -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.5);
    cursor: pointer;
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    font-weight: bold !important;
    font-size: 18px;
    margin-bottom: 18px;
    text-decoration: none
}

#mc_embed_signup .ihBlueBtn {
    height: auto
}

.ihBlueBtn:hover {
    top: -1px;
    color: #fff !important;
    border-color: #018cff !important;
    background: #018cff !important;
    text-decoration: none;
    -webkit-box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.45);
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.45);
    -webkit-transition-duration: 0s;
    transition-duration: 0s
}

.ihEmailSignUp h1 {
    color: #0887d4
}

.ihEmailSignUpTextFields {
    width: 33%;
    float: left
}

.ihEmailFormSignupColTwo {
    width: 66%;
    float: left
}

#mc-embedded-subscribe-form .ihEmailFormSignupColTwo .input-group {
    float: left;
    width: 100%
}

.ihEmailFormSignupCols {
    width: 50%;
    float: left
}

.ihEmailSignUp .input-group label {
    font-weight: normal;
    padding-left: 10px
}

.ihThirdPartyDisclaimer h2 {
    text-align: left;
    margin-bottom: 20px
}

.ihThirdPartyDisclaimer a {
    overflow-wrap: break-word;
    width: 100%;
    font-size: 16px
}

.ihThirdPartyDisclaimer a strong {
    overflow-wrap: break-word;
    font-size: 14px;
    text-align: left
}

.ihThirPartyDisclaimerBtn:after {
    content: '\f105\f105';
    font-family: 'FontAwesome';
    padding-left: 5px
}

.fa-external-link:before {
    content: "\f08e";
    font-family: 'FontAwesome';
    font-style: normal;
    padding-left: 4px;
    font-size: 16px
}

#mc_embed_signup form {
    display: block;
    position: relative;
    text-align: left;
    padding: 10px 0 10px 3%
}

#mc_embed_signup h2 {
    font-weight: bold;
    padding: 0;
    margin: 1px 0;
    font-size: 1.4em
}

#mc_embed_signup input {
    border: 1px solid #ABB0B2;
    border-radius: 3px
}

#mc_embed_signup input[type=checkbox] {
    -webkit-appearance: checkbox
}

#mc_embed_signup input[type=radio] {
    -webkit-appearance: radio
}

#mc_embed_signup input:focus {
    border-color: #333
}

#mc_embed_signup .button {
    clear: both;
    border: 0 none;
    border-radius: 4px;
    -webkit-transition: all 0.23s ease-in-out 0s;
    transition: all 0.23s ease-in-out 0s;
    color: #FFFFFF;
    cursor: pointer;
    display: inline-block;
    font-size: 15px;
    font-weight: normal;
    height: 32px;
    line-height: 32px;
    margin: 0 5px 10px 0;
    padding: 0 22px;
    text-align: center;
    text-decoration: none;
    vertical-align: top;
    white-space: nowrap;
    width: auto
}

#mc_embed_signup .button:hover {
    background-color: #777
}

#mc_embed_signup .small-meta {
    font-size: 11px
}

#mc_embed_signup .nowrap {
    white-space: nowrap
}

#mc_embed_signup .mc-field-group {
    clear: left;
    position: relative;
    width: 96%;
    padding-bottom: 3%;
    min-height: 50px
}

#mc_embed_signup .size1of2 {
    clear: none;
    float: left;
    display: inline-block;
    width: 46%;
    margin-right: 4%
}

* html #mc_embed_signup .size1of2 {
    margin-right: 2%
}

#mc_embed_signup .mc-field-group label {
    display: block;
    margin-bottom: 3px;
    line-height: 16px
}

#mc_embed_signup .mc-field-group input {
    display: block;
    width: 100%;
    padding: 3px 0;
    text-indent: 2%
}

#mc_embed_signup .mc-field-group select {
    display: inline-block;
    width: 99%;
    padding: 5px 0;
    margin-bottom: 2px
}

#mc_embed_signup .datefield,
#mc_embed_signup .phonefield-us {
    padding: 5px 0
}

#mc_embed_signup .datefield input,
#mc_embed_signup .phonefield-us input {
    display: inline;
    width: 60px;
    margin: 0 2px;
    letter-spacing: 1px;
    text-align: center;
    padding: 5px 0 2px 0
}

#mc_embed_signup .phonefield-us .phonearea input,
#mc_embed_signup .phonefield-us .phonedetail1 input {
    width: 40px
}

#mc_embed_signup .datefield .monthfield input,
#mc_embed_signup .datefield .dayfield input {
    width: 30px
}

#mc_embed_signup .datefield label,
#mc_embed_signup .phonefield-us label {
    display: none
}

#mc_embed_signup .indicates-required {
    text-align: right;
    font-size: 11px;
    margin-right: 4%
}

#mc_embed_signup .asterisk {
    color: #e85c41;
    font-size: 150%;
    font-weight: normal;
    position: relative;
    top: 5px
}

#mc_embed_signup .clear {
    clear: both
}

#mc_embed_signup .mc-field-group.input-group ul {
    margin: 0;
    padding: 5px 0;
    list-style: none
}

#mc_embed_signup .mc-field-group.input-group ul li {
    display: block;
    padding: 0 0;
    margin: 0
}

#mc_embed_signup .mc-field-group.input-group label {
    display: inline
}

#mc_embed_signup .mc-field-group.input-group input {
    display: inline;
    width: auto;
    border: none
}

#mc_embed_signup div#mce-responses {
    float: left;
    top: -1.4em;
    padding: 0em .5em 0em .5em;
    overflow: hidden;
    width: 90%;
    margin: 0 5%;
    clear: both
}

#mc_embed_signup div.response {
    margin: 1em 0;
    padding: 1em .5em .5em 0;
    font-weight: bold;
    float: left;
    top: -1.5em;
    z-index: 1;
    width: 80%
}

#mc_embed_signup #mce-error-response {
    display: none
}

#mc_embed_signup #mce-success-response {
    color: #529214;
    display: none
}

#mc_embed_signup label.error {
    display: block;
    float: none;
    width: auto;
    margin-left: 1.05em;
    text-align: left;
    padding: .5em 0
}

#mc-embedded-subscribe {
    clear: both;
    width: auto;
    display: block;
    margin: 1em 0 1em 5%
}

#mc_embed_signup #num-subscribers {
    font-size: 1.1em
}

#mc_embed_signup #num-subscribers span {
    padding: .5em;
    border: 1px solid #ccc;
    margin-right: .5em;
    font-weight: bold
}

#mc_embed_signup #mc-embedded-subscribe-form div.mce_inline_error {
    display: inline-block;
    margin: 2px 0 0 0;
    padding: 0 10px;
    background-color: rgba(255, 255, 255, 0.85);
    border-radius: 3px;
    font-size: 14px;
    font-weight: normal;
    z-index: 1;
    color: #e85c41
}

#mc_embed_signup #mc-embedded-subscribe-form input.mce_inline_error {
    border: 2px solid #e85c41
}

#mc_embed_signup {
    background: #fff;
    clear: left;
    font: 14px Helvetica, Arial, sans-serif
}

#mc_embed_signup_scroll h4 {
    font-size: 14px;
    float: right;
    margin-right: 10px
}

.mc-field-group.input-group strong {
    font-size: 17px;
    color: #6c7581
}

.mc-field-group.input-group legend {
    margin: 0 0 10px 0;
    color: #6c7581
}

#mc-embedded-subscribe-form .ihEmailFormSignupColTwo .input-group {
    float: left;
    width: 95%
}
