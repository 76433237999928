@font-face {
    font-family: Nexa;
    src: url(https://ihmvcu-mkt-stage1-res.adobe-campaign.com/res/ihmvcu_mkt_stage1/148064c2aa4b1720041802389e4f510b.woff2) format("woff2"), url(https://ihmvcu-mkt-stage1-res.adobe-campaign.com/res/ihmvcu_mkt_stage1/715c2aca0945176bf7320a8a3d8ea118.woff) format("woff");
    font-weight: 700;
    font-style: normal
}

@font-face {
    font-family: Nexa;
    src: url(https://ihmvcu-mkt-stage1-res.adobe-campaign.com/res/ihmvcu_mkt_stage1/eef6da8e09866c546dcc9feaf839cccf.woff2) format("woff2"), url(https://ihmvcu-mkt-stage1-res.adobe-campaign.com/res/ihmvcu_mkt_stage1/933bc8b6c0cd08f61e460160edab0dd9.woff) format("woff");
    font-weight: 400;
    font-style: normal
}

body {
    font-family: NexaLight, Calibri, Candara, Segoe, Segoe UI, Optima, Arial, sans-serif
}

body.no-scroll {
    overflow-y: scroll
}

.popup,
body.no-scroll {
    position: fixed;
    width: 100%
}

.popup {
    align-items: center;
    background: rgba(1, 140, 255, .5);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    opacity: 0;
    top: 0;
    visibility: hidden;
    transition: .3s cubic-bezier(.25, .46, .45, .94)
}

.popup.is-open {
    opacity: 1;
    visibility: visible;
    z-index: 999
}

.popup.is-open .popup__inner {
    box-shadow: 0 0 3px 3px rgba(12, 20, 27, .125)
}

.popup.is-open .popup__bar {
    opacity: 1;
    transition-delay: .3s
}

.popup.is-open .popup__frame {
    opacity: 1;
    transform: scale(1)
}

.popup.is-open .popup__close {
    opacity: 1;
    transition: .4s cubic-bezier(.55, .085, .68, .53);
    transition-delay: .2s
}

.popup__inner {
    background: #fff;
    height: auto;
    max-height: calc(100vh - 20px);
    margin: 10px;
    position: relative;
    height: calc(100% - 20px);
    width: calc(100% - 20px)
}

.popup__bar {
    display: flex;
    justify-content: flex-end;
    opacity: 0;
    transition: opacity .4s
}

.popup__close {
    color: #fff;
    cursor: pointer;
    font-size: 1rem;
    line-height: calc(1em + 5px);
    text-align: center;
    align-items: center;
    background-color: #266dce;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0 0 3px 2px rgba(12, 20, 27, .25);
    display: flex;
    height: calc(1em + 10px);
    justify-content: center;
    opacity: 0;
    position: absolute;
    right: -.688em;
    top: -.688em;
    width: calc(1em + 10px);
    z-index: 1;
    transition: .3s cubic-bezier(.55, .085, .68, .53)
}

@media (max-width:33.99em) {
    .popup__close {
        font-size: .75em;
        right: -.5em;
        top: -.5em
    }
}

.popup .popup__closeBtn {
    display: none
}

.popup .popup__closeBtn.is-shown {
    font-weight: 400;
    text-transform: uppercase;
    border: 0;
    display: block;
    margin: 0 10px 10px
}

.popup__frame {
    height: 100%;
    max-height: 100%;
    min-width: 100%;
    opacity: 0;
    width: 100%;
    transform: scale(.5);
    transition: .35s cubic-bezier(.55, .085, .68, .53)
}
