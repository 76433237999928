
body.custom-modal-open {
	overflow: hidden;
}

.custom-modal-dialog-helper {
    display: none;
}

.custom-modal-dialog {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, .5);
    margin: 40px auto;
    max-width: 968px;
    padding: 2%;
    position: relative;
    width: 85%;
	opacity: 0;
	visibility: hidden;
    overflow: visible;
    border: 0;
    &[open] {
        opacity: 1;
		visibility: visible;
    }
	&::backdrop {
        background: rgba(8, 135, 212, .8);
	}
}

.custom-modal-dialog__close {
    background: none;
    border: 0;
    padding: 0;
    position: absolute;
    right: -21px;
    top: -17px;
    cursor: pointer;
}


//Editor 

.sfPageEditor {
    .custom-modal-dialog-helper {
        display: block;
        outline: 1px dashed rgb(18, 123, 221);
    }
    .custom-modal-dialog {
        position: static;
        visibility: visible;
        opacity: 1;
        height: auto;
        display: block;
        .ihOverlayCloseBtn {
            display: none;
        }
    }

    .custom-modal-dialog__close {
        display: none;
    }
}